<template>
  <div class="flex-1 flex sm:items-stretch sm:justify-start">
    <div
      class="flex-shrink-0 flex items-center cursor-pointer"
      @click="redirect()"
    >
      <PodiemIconBlue2025
        v-if="isNavbarOpaque || isSmallScreen"
        class="w-[140px]"
      />
      <PodiemIconWhite2025 v-else class="w-[140px]" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import NewPodiemIcon from "../icons/NewPodiemIcon.vue";
import PodiemIconWhite2025 from "../icons/PodiemIconWhite2025.vue";
import PodiemIconBlue2025 from "../icons/PodiemIconBlue2025.vue";

import * as util from "@/js/utils";
import router from "@/router";

export default {
  name: "Brand",
  components: {
    NewPodiemIcon,
    PodiemIconWhite2025,
    PodiemIconBlue2025,
  },
  setup() {
    const store = useStore();

    const redirect = () => {
      // router.push('/')
      location.href = "/";
    };

    return {
      isNavbarOpaque: computed(
        () => store.getters["navigation/GET_navbarOpaqueStatus"],
      ),
      isSmallScreen: util.isSmallScreen,
      redirect,
    };
  },
};
</script>
